/*------------------------------------------------------------------
[Master Stylesheet]

Project:  Atreves
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[Table of contents]

1. Body
2. Header  / .header
3. Banner  /.banner-con
4. Explore  /.explore-con
5. About  /.about-con
6. Service  /.service-con
7. Join  /.join-con
8. Place  /.place-con
9. Testimonial  /.testimonial-con
10. Partner  /.partner-con
11. Footer  /.footer-con

*/
/*------------------------------------------------------------------
# [Color Codes]

#    --e-global-color-primary: #ffffff;
#    --e-global-color-secondary: #0f0200;
#    --e-global-color-accent: #fd8916;
#    --e-global-color-text: #4c4c4c;
# 	 --e-global-color-white: #ffffff;
#    --e-global-color-black: #000000;
#    --e-global-color-very-red: #201615;
#    --e-global-color-very-dark-red: #271b19;
#    --e-global-color-light-grayish-red: #a29a99;
#    --e-global-color-light-grayish-orange: #fcfaf5;
#    --e-global-color-light-grayish-yellow: #f5f4f1;
#    --e-global-color-light-yellow: #ffc045;
#    --e-global-color-star-yellow: #ffea00;
#    --e-global-color-pure-red: #ff0000;

*/
/*------------------------------------------------------------------
# [Typography]

Body copy: "DM Sans", sans-serif;
Heading: "Playfair Display", serif;
*/

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

body {
  font-family: "DM Sans", sans-serif;
  /* font-family: "Playfair Display", serif; */
}

:root {
  --e-global-color-primary: #ffffff;
  --e-global-color-secondary: #0f0200;
  --e-global-color-accent: #fd8916;
  --e-global-color-text: #4c4c4c;
  --e-global-color-white: #ffffff;
  --e-global-color-black: #000000;
  --e-global-color-very-red: #201615;
  --e-global-color-very-dark-red: #271b19;
  --e-global-color-light-grayish-red: #a29a99;
  --e-global-color-light-grayish-orange: #fcfaf5;
  --e-global-color-light-grayish-yellow: #f5f4f1;
  --e-global-color-light-yellow: #ffc045;
  --e-global-color-star-yellow: #141713;
  --e-global-color-pure-red: #ff0000;
}

h1 {
  font-size: 75px;
  line-height: 82px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
}

h2 {
  font-size: 45px;
  line-height: 58px;
  font-weight: 700;
  color: var(--e-global-color-secondary);
  font-family: "Playfair Display", serif;
}

h3 {
  font-size: 21px;
  line-height: 26px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
}

h4 {
  font-size: 19px;
  line-height: 26px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
}

h5 {
  font-size: 17px;
  line-height: 26px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
}

h6 {
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  color: var(--e-global-color-accent);
}

p {
  line-height: 26px;
  font-weight: 400;
  color: var(--e-global-color-text);
}

.text-size-18 {
  font-size: 17px;
  line-height: 26px;
  font-weight: 400;
  color: var(--e-global-color-text);
}

.text-size-16 {
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  color: var(--e-global-color-text);
}

.text-size-14 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--e-global-color-text);
}

.text-size-13 {
  font-size: 13px;
  line-height: 1.5;
  font-weight: 400;
  color: var(--e-global-color-text);
}

.all_button {
  font-size: 17px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  border-radius: 8px;
  position: relative;
  display: inline-block;
  color: var(--e-global-color-white);
  background: var(--e-global-color-accent);
  font-family: "Urbanist", sans-serif;
  transition: all 0.8s ease-in-out;
}

.all_button:hover {
  color: var(--e-global-color-white);
  background: var(--e-global-color-secondary);
}

.all_button i {
  font-size: 11px;
  margin-left: 20px;
  transition: all 0.8s ease-in-out;
  color: var(--e-global-color-white);
}

.all_button:hover i {
  transform: translateX(4px);
}

html {
  scroll-behavior: smooth;
}

/* Home Page */

.header {
  padding: 22px 0px;
  position: relative;
  z-index: 5;
}

.header .logo img {
  width: 145px;
}

.navbar-collapse .navbar-nav {
  text-align: center;
  align-items: center;
  display: inherit;
}

.navbar-expand-lg {
  position: relative;
  z-index: 1;
}

.navbar-brand {
  margin-left: 0;
  margin-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-nav li {
  margin: 0 20px;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.navbar-nav li:first-child {
  margin-left: 0;
}

.navbar-nav .nav-item a {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: var(--e-global-color-secondary) !important;
  transition: all 0.3s ease-in-out;
}

.navbar-nav .nav-item a:hover {
  color: var(--e-global-color-accent) !important;
  background-color: transparent;
}

.navbar-nav .active > a {
  color: var(--e-global-color-accent) !important;
}

.navbar-nav li:last-child {
  margin-right: 0;
  padding-right: 0;
  margin-left: 0;
}

.navbar-collapse .drop-down-pages {
  text-align: left;
  margin-left: 0;
}

.navbar-nav .dropdown-menu {
  position: absolute;
  left: 0;
  top: 62px;
  min-width: 212px;
  padding: 0;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 100px rgb(0 0 0 / 10%);
  background-color: var(--e-global-color-white);
}

.navbar-nav .drop-down-pages li {
  margin: 0;
  border-bottom: 1px solid var(--e-global-color-white);
}

.navbar-nav .drop-down-pages .nav-item a {
  font-size: 15px;
  line-height: 16px;
  font-weight: 400;
  padding: 10px 20px;
  color: var(--e-global-color-nav-text) !important;
  transition: all 0.3s ease-in-out;
}

.navbar-nav .drop-down-pages li:hover::after {
  display: none;
}

.navbar-nav .drop-down-pages li:first-child a::before {
  content: '';
  width: 24px;
  height: 12px;
  position: absolute;
  background-image: url(../images/nav-node.png);
  top: -12px;
  left: 8%;
  transition: all 0.3s ease-in-out;
}

.navbar-nav .drop-down-pages li:first-child a:hover::before {
  background-image: url(../images/nav-node-hover.png);
}

.navbar-nav .drop-down-pages .active:first-child > a::before {
  background-image: url(../images/nav-node-hover.png);
}

.navbar-nav .drop-down-pages li:last-child {
  margin: 0;
  border: none;
  display: block !important;
}

.navbar-nav .nav-item .dropdown-item:hover {
  border-radius: 0;
  color: var(--e-global-color-white) !important;
  background-color: var(--e-global-color-accent) !important;
}

.navbar-nav .drop-down-pages .active > a {
  border-radius: 0;
  color: var(--e-global-color-white) !important;
  background-color: var(--e-global-color-accent) !important;
}

.dropdown-item.active,
.dropdown-item:active {
  border-radius: 0;
  color: var(--e-global-color-white) !important;
  background-color: var(--e-global-color-accent) !important;
}

.navbar-nav .drop-down-pages .active > a:hover {
  border-radius: 0;
  color: var(--e-global-color-white) !important;
  background-color: var(--e-global-color-accent) !important;
}

.navbar-nav .drop-down-pages .nav-item:first-child a:hover {
  border-radius: 10px 10px 0 0;
}

.navbar-nav .drop-down-pages .active:first-child > a {
  border-radius: 10px 10px 0 0;
}

.navbar-nav .drop-down-pages .nav-item:last-child a:hover {
  border-radius: 0 0 10px 10px;
}

.navbar-nav .drop-down-pages .active:last-child > a {
  border-radius: 0 0 10px 10px;
}

.navbar-expand-lg .drop-down-pages .nav-link {
  padding-left: 0;
}

.navbar-nav .nav-item .book_now {
  font-size: 15px;
  line-height: 16px;
  font-weight: 700;
  min-width: 160px;
  height: 52px;
  padding: 18px;
  border-radius: 8px;
  position: relative;
  text-align: center;
  display: inline-block;
  color: var(--e-global-color-white) !important;
  background-color: var(--e-global-color-accent) !important;
  transition: all 0.3s ease-in-out;
}

.navbar-nav .nav-item .book_now i {
  font-size: 11px;
  margin-left: 24px;
  transition: all 0.3s ease-in-out;
  color: var(--e-global-color-white);
}

.navbar-nav .nav-item .book_now:hover {
  color: var(--e-global-color-white) !important;
  background-color: var(--e-global-color-secondary) !important;
}

.navbar-nav .nav-item .book_now:hover i {
  transform: translateX(4px);
}

/* Banner */

.banner-con {
  padding: 210px 0 330px;
}

.banner-con:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.banner-con .banner_content {
  margin: 0 23px;
}

.banner-con .banner_content h3 {
  width: 350px;
  padding: 0 54px;
  margin: 0 auto 14px;
  letter-spacing: 2.0px;
  word-spacing: 2.0px;
  position: relative;
}

.banner-con .banner_content h3::before,
.banner-con .banner_content h3::after {
  content: "";
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 42px;
  height: 2px;
  position: absolute;
  background-color: var(--e-global-color-accent);
}

.banner-con .banner_content h3::before {
  left: 0;
}

.banner-con .banner_content h3::after {
  right: 0;
}

.banner-con .banner_content h1 {
  margin-bottom: 54px;
}

.banner-con .banner_content .banner-box {
  width: 874px;
  height: 113px;
  padding: 20px 30px;
  border-radius: 8px;
  text-align: left;
  background-color: var(--e-global-color-light-grayish-yellow);
}

.banner-con .banner-box .form-group {
  margin-right: 12px;
}

.banner-con .banner-box .upper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.banner-con .banner-box .form-group i {
  font-size: 10px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  margin-right: 10px;
  border-radius: 100%;
  text-align: center;
  color: var(--e-global-color-white);
  background-color: var(--e-global-color-accent);
}

.banner-con .banner-box label {
  font-size: 15px;
  line-height: 14px;
  font-weight: 500;
  margin-bottom: 0;
  color: var(--e-global-color-secondary);
}

.banner-con .banner-box input {
  font-size: 11px;
  line-height: 12px;
  font-weight: 400;
  padding: 6px 20px;
  width: 226px;
  height: 37px;
  position: relative;
  color: var(--e-global-color-text);
  background-color: var(--e-global-color-white);
  border: 1px solid transparent;
  border-radius: 5px;
  overflow: visible;
  outline: none;
}

.banner-con .banner-box input:focus {
  border: 1px solid var(--e-global-color-accent);
}

.banner-con .banner-box button {
  font-size: 19px;
  width: 41px;
  height: 73px;
  border: none;
  float: right;
  text-align: center;
  color: var(--e-global-color-white);
  background-color: var(--e-global-color-accent);
  border-radius: 8px;
  overflow: visible;
  outline: none;
  transition: all 0.8s ease-in-out;
}

.banner-con .banner-box button:hover {
  background-color: var(--e-global-color-secondary);
}

.banner-con hr {
  position: absolute;
  bottom: 0;
  right: 105px;
  border: none;
  width: 1px;
  height: 73px;
  background-color: #e3e1da;
}

.banner-con .top-btn {
  display: inline-block;
  left: 0;
  right: 0;
  bottom: -236px;
  margin: 0 auto;
  position: absolute;
}

.banner-con .top-btn figure {
  transition: all 0.3s ease-in-out;
}

.banner-con .top-btn figure:hover {
  transform: translateY(-5px);
}

/* Explore */

.explore-con {
  padding: 114px 0 140px 400px;
  overflow: hidden;
}

.explore-con .explore_content h6 {
  margin-bottom: 8px;
}

.explore-con .explore_content h2 {
  margin-bottom: 45px;
}

.explore-con .explore-box {
  position: relative;
  transition: all 0.3s ease-in-out;
}

.explore-con .explore-box .image {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.explore-con .explore-box .image::before {
  content: "";
  position: absolute;
  opacity: 50%;
  width: 100%;
  height: 100%;
  bottom: 0;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../images/explore-fadeimage.png);
  transition: all 0.3s ease-in-out;
}

.explore-con .explore-box:hover .image::before {
  display: none;
}

.explore-con .explore-box:hover .image {
  opacity: 1;
  background-color: var(--e-global-color-black);
}

.explore-con .explore-box .image img {
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
}

.explore-con .explore-box:hover .image img {
  opacity: 70%;
  transform: scale(1.1);
}

.explore-con .explore-box .rating {
  left: 20px;
  top: 18px;
  padding: 7px 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: absolute;
  background-color: rgb(0 0 0 / 35%);
}

.explore-con .explore-box .rating i {
  font-size: 8px;
  margin-right: 4px;
  color: var(--e-global-color-star-yellow);
}

.explore-con .explore-box .rating span {
  font-size: 11px;
  line-height: 12px;
  font-weight: 400;
}

.explore-con .explore-box .content {
  position: absolute;
  bottom: 30px;
  left: 20px;
}

.explore-con .explore-box .content .place {
  margin-bottom: 6px;
}

.explore-con .explore-box .content i {
  font-size: 11px;
  margin-right: 2px;
  color: var(--e-global-color-white);
}

.explore-con .explore-box .content span {
  font-size: 11px;
  line-height: 12px;
  font-weight: 500;
}

.explore-con .owl-carousel .owl-nav {
  display: block !important;
  margin-top: 0 !important;
  position: absolute;
  right: 405px;
  top: -114px;
}

.explore-con .owl-carousel .owl-prev,
.explore-con .owl-carousel .owl-next {
  font-size: 17px !important;
  width: 57px;
  height: 57px;
  line-height: 57px !important;
  border-radius: 100% !important;
  position: relative;
  text-align: center;
  color: var(--e-global-color-secondary) !important;
  background-color: transparent !important;
  border: 1px solid var(--e-global-color-secondary) !important;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.explore-con .owl-carousel .owl-prev {
  left: -8px;
}

.explore-con .owl-carousel .owl-next {
  right: -8px;
}

.explore-con .owl-carousel .owl-prev:hover,
.explore-con .owl-carousel .owl-next:hover {
  color: var(--e-global-color-white) !important;
  background-color: var(--e-global-color-accent) !important;
  border: 1px solid var(--e-global-color-accent) !important;
}

/* About */

.about-con {
  padding: 138px 0 145px;
  background-color: var(--e-global-color-light-grayish-orange);
}

.about-con:before {
  content: "";
  position: absolute;
  opacity: 12%;
  width: 100%;
  height: 100%;
  top: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../images/about-backgroundimage.jpg);
}

.about-con .about_wrapper .about-image {
  position: relative;
  top: 0;
  left: 20px;
}

.about-con .about_wrapper .about-image img {
  border-radius: 100%;
}

.about-con .about_wrapper .about-locationimage {
  position: absolute;
  bottom: 6px;
  right: 50px;
}

.about-con .about_wrapper .box {
  left: -60px;
  bottom: 290px;
  width: 258px;
  height: 73px;
  padding: 10px 15px;
  border-radius: 36px;
  display: flex;
  align-items: center;
  position: absolute;
  background-color: var(--e-global-color-white);
  box-shadow: 2px 20px 29px 20px rgb(0 0 0 / 3%);
}

.about-con .about_wrapper .box .text {
  padding-left: 10px;
}

.about-con .about_wrapper .box .value {
  font-size: 19px;
  line-height: 24px;
  font-weight: 700;
  display: block;
  margin-bottom: 0;
  color: var(--e-global-color-accent);
}

.about-con .about_wrapper .box .review {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: var(--e-global-color-text);
}

.about-con .about_wrapper .box figure {
  margin: 0 -12px;
  display: inline-block;
}

.about-con .about_wrapper .box figure:first-child {
  margin-left: 0;
}

.about-con .about_wrapper .box figure:last-child {
  margin-right: 0;
}

.about-con .about_wrapper .box img {
  width: 48px;
  border-radius: 100%;
  border: 2px solid var(--e-global-color-white);
}

.about-con .about_content h6 {
  margin-bottom: 10px;
}

.about-con .about_content h2 {
  margin-bottom: 20px;
}

.about-con .about_content .text {
  margin-bottom: 50px;
}

.about-con .about_content li {
  width: 134px;
  height: 139px;
  padding: 28px 32px;
  margin: 0 8px;
  border-radius: 8px;
  display: inline-block;
  background-color: var(--e-global-color-white);
  border-bottom: 2px solid var(--e-global-color-accent);
  transition: all 0.3s ease-in-out;
}

.about-con .about_content li:hover {
  box-shadow: 0 1px 94px 1px rgb(0 0 0 / 15%);
}

.about-con .about_content li .value {
  font-size: 35px;
  line-height: 36px;
  font-weight: 700;
  display: block;
  margin-bottom: 6px;
  color: var(--e-global-color-accent);
}

.about-con .about_content li:first-child {
  margin-left: 0;
}

.about-con .about_content li:last-child {
  margin-right: 0;
}

/* Service */

.service-con {
  padding: 135px 0 100px;
}

.service-con .service_content h6 {
  margin-bottom: 10px;
}

.service-con .service_content h2 {
  margin-bottom: 45px;
}

.service-con .service_content .text {
  margin-bottom: 52px;
  padding: 0 40px;
  color: var(--e-global-color-text);
}

.service-con .service-box {
  padding: 5px 5px;
  margin-bottom: 30px;
  background-color: var(--e-global-color-white);
  border-bottom: 2px solid var(--e-global-color-white);
  box-shadow: 0 1px 94px 1px rgb(0 0 0 / 3%);
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-radius: 12px;
  border: 1px solid #e7e2e2;
  min-height: 400px; /* Adjust based on typical content size */
  justify-content: space-between; /* Helps distribute space evenly inside the card */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 400px; /* Fixed height for all cards */
}

.service-con .service-box:hover {
  border-bottom: 2px solid var(--e-global-color-accent);
  border-top: 2px solid var(--e-global-color-accent);
  box-shadow: 0 1px 94px 1px rgb(0 0 0 / 3%);
}

.service-con .service-box .icon {
  width: 100px;
  height: 100px;
  min-height: 60px;
  margin-bottom: 30px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.service-con .service-box:hover .icon {
  transform: translateY(-5px);
}

.service-con .service-box h4 {
  margin-bottom: 14px;
}

.service-con .service-box p {
  margin-bottom: 24px;
}

.service-con .service-box .learn_more {
  font-size: 15px;
  line-height: 14px;
  font-weight: 500;
  text-align: center;
  position: relative;
  display: inline-block;
  color: var(--e-global-color-accent);
  transition: all 0.3s ease-in-out;
}

.service-con .service-box .learn_more i {
  font-size: 11px;
  margin-left: 10px;
  transition: all 0.8s ease-in-out;
  color: var(--e-global-color-accent);
}

.service-con .service-box .learn_more:hover {
  color: var(--e-global-color-text);
}

.service-con .service-box .learn_more:hover i {
  transform: translateX(4px);
  color: var(--e-global-color-text);
}

/* Join */

.join-con {
  padding: 138px 0 140px;
  background-color: var(--e-global-color-black);
}

.join-con:before {
  content: "";
  position: absolute;
  opacity: 50%;
  width: 100%;
  height: 100%;
  top: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../images/join-backgroundimage.jpg);
}

.join-con .join_content h6 {
  margin-bottom: 10px;
}

.join-con .join_content h2 {
  margin-bottom: 40px;
}

.join-con .join_content .all_button {
  padding: 20px 39px;
}

/* Place */

.place-con {
  padding: 136px 0 140px;
}

.place-con .place_content h6 {
  margin-bottom: 10px;
}

.place-con .place_content h2 {
  margin-bottom: 45px;
}

.place-con .place-box {
  padding: 15px 15px 20px 15px;
  border-radius: 20px;
  background-color: var(--e-global-color-white);
  box-shadow: 0 1px 94px 1px rgb(0 0 0 / 3%) inset;
  border: 1px solid var(--e-global-color-white);
  transition: all 0.3s ease-in-out;
}

.place-con .place-box:hover {
  border: 1px solid var(--e-global-color-accent);
}

.place-con .place-box .image {
  overflow: hidden;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  background-color: var(--e-global-color-black);
}

.place-con .place-box .image img {
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.place-con .place-box:hover .image img {
  opacity: 80%;
  transform: scale(1.1);
}

.place-con .place-box .lower_content {
  padding: 22px 22px 0;
}

.place-con .place-box .content {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.place-con .place-box .calendar {
  display: flex;
  align-items: center;
  padding-right: 14px;
}

.place-con .place-box .people {
  display: flex;
  align-items: center;
  padding-left: 14px;
  border-left: 1px solid #e2e2e2;
}

.place-con .place-box .content i {
  font-size: 11px;
  margin-right: 10px;
  color: var(--e-global-color-accent);
}

.place-con .place-box .content span {
  font-size: 15px;
  line-height: 14px;
  font-weight: 400;
  color: var(--e-global-color-text);
}

.place-con .place-box h4 {
  margin-bottom: 18px;
  display: inline-block;
  color: var(--e-global-color-secondary);
  transition: all 0.3s ease-in-out;
}

.place-con .place-box h4:hover {
  color: var(--e-global-color-accent);
}

.place-con .place-box .value {
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e2e2e2;
}

.place-con .place-box .value span {
  font-size: 27px;
  line-height: 28px;
  font-weight: 700;
  color: var(--e-global-color-accent);
}

.place-con .place-box .book_now {
  font-size: 15px;
  line-height: 14px;
  font-weight: 500;
  text-align: center;
  position: relative;
  display: inline-block;
  color: var(--e-global-color-secondary);
  transition: all 0.3s ease-in-out;
}

.place-con .place-box .book_now i {
  font-size: 11px;
  margin-left: 10px;
  transition: all 0.8s ease-in-out;
  color: var(--e-global-color-secondary);
}

.place-con .place-box .book_now:hover {
  color: var(--e-global-color-accent);
}

.place-con .place-box .book_now:hover i {
  transform: translateX(4px);
  color: var(--e-global-color-accent);
}

.place-con .owl-carousel .owl-nav {
  display: block !important;
  margin-top: 52px !important;
  position: relative;
}

.place-con .owl-carousel .owl-prev,
.place-con .owl-carousel .owl-next {
  font-size: 17px !important;
  width: 57px;
  height: 57px;
  line-height: 57px !important;
  border-radius: 100% !important;
  position: relative;
  text-align: center;
  color: var(--e-global-color-secondary) !important;
  background-color: transparent !important;
  border: 1px solid var(--e-global-color-secondary) !important;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.place-con .owl-carousel .owl-prev {
  left: -4px;
}

.place-con .owl-carousel .owl-next {
  right: -4px;
}

.place-con .owl-carousel .owl-prev:hover,
.place-con .owl-carousel .owl-next:hover {
  color: var(--e-global-color-white) !important;
  background-color: var(--e-global-color-accent) !important;
  border: 1px solid var(--e-global-color-accent) !important;
}

/* Testimonial */

.testimonial-con {
  padding: 158px 0 195px;
  background-color: var(--e-global-color-light-grayish-orange);
}

.testimonial-con:before {
  content: "";
  position: absolute;
  opacity: 12%;
  width: 100%;
  height: 100%;
  top: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../images/testimonial-backgroundimage.jpg);
}

.testimonial-con .testimonial_wrapper {
  top: 35px;
  height: 380px;
  width: 380px;
  border-radius: 100%;
  background: #f5f0e5;
}

.testimonial-con .testimonial_wrapper .image {
  position: absolute;
}

.testimonial-con .testimonial_wrapper .image img {
  border-radius: 100%;
}

.testimonial-con .testimonial_wrapper .testimonial-circle {
  height: 155px;
  width: 155px;
  line-height: 155px;
  border-radius: 100%;
  text-align: center;
  background: var(--e-global-color-white);
  position: relative;
  top: 112px;
  left: 0;
  right: 0;
  margin: auto;
}

.testimonial-con .testimonial_wrapper .testimonial-image1 {
  right: -4px;
  top: -54px;
}

.testimonial-con .testimonial_wrapper .testimonial-image2 {
  left: 0;
  top: 56px;
}

.testimonial-con .testimonial_wrapper .testimonial-image3 {
  left: 62px;
  bottom: -30px;
}

.testimonial-con .testimonial_wrapper .testimonial-image4 {
  right: -6px;
  bottom: 80px;
}

.testimonial-con .testimonial_contentwrapper {
  padding: 0 60px 0 40px;
}

.testimonial-con .testimonial_content h2 {
  margin-bottom: 40px;
  font-size: 27px;
}

.testimonial-con .testimonial-box p {
  line-height: 26px;
  margin-bottom: 32px;
}

.testimonial-con .testimonial-box .content {
  display: flex;
  align-items: center;
}

.testimonial-con .testimonial-box .testimonial-quote {
  height: 64px;
  width: 64px;
  line-height: 64px;
  text-align: center;
  display: inline-block;
  position: relative;
  margin-right: 16px;
  margin-bottom: 0;
  border-radius: 100px;
  background-color: var(--e-global-color-accent);
  transition: all 0.3s ease-in-out;
}

.testimonial-con .testimonial-box .name {
  font-size: 19px;
  line-height: 20px;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  color: var(--e-global-color-secondary);
  margin-bottom: 6px;
  display: block;
}

.testimonial-con .testimonial-box .review {
  font-size: 15px;
  line-height: 14px;
  font-weight: 400;
  color: var(--e-global-color-text);
}

.testimonial-con .pagination-outer {
  position: relative;
  display: inline-block;
  top: 28px;
  right: -12px;
}

#carouselExampleControls .carousel-control-prev,
#carouselExampleControls .carousel-control-next {
  font-size: 19px;
  line-height: 20px;
  top: 0;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  color: var(--e-global-color-secondary);
}

#carouselExampleControls .carousel-control-prev {
  left: 0;
}

#carouselExampleControls .carousel-control-next {
  right: -38px;
}

#carouselExampleControls .carousel-control-prev:hover,
#carouselExampleControls .carousel-control-next:hover {
  color: var(--e-global-color-accent);
}

/* Partner */

.partner-con {
  padding: 135px 0 130px;
}

.partner-con .partner_content h6 {
  margin-bottom: 10px;
}

.partner-con .partner_content h2 {
  margin-bottom: 35px;
  font-size: 27px;
}

.partner-con ul {
  width: 100%;
  float: left;
}

.partner-con ul li {
  border-right: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
  width: 25%;
  height: 156px;
  line-height: 156px;
  transition: 0.3s all ease-in-out;
  float: left;
  text-align: center;
}

.partner-con ul li:last-child {
  display: inline-block;
  border-right: none;
  border-bottom: 1px solid #e7e7e7;
}

.partner-con .second li {
  display: inline-block;
  border-right: 1px solid #e7e7e7;
  border-bottom: none;
}

.partner-con .second li:last-child {
  display: inline-block;
  border-bottom: none;
}

.partner-con ul li figure img {
  transition: 0.3s all ease-in-out;
  /*filter: grayscale(100%);*/
}

.partner-con ul li:hover figure img {
  filter: none;
  transform: scale(1.1);
}

/* Footer */

.footer-con .footer_upperportion {
  background: var(--e-global-color-very-red);
}

.footer-con .upper_portion {
  display: flex;
  align-items: center;
  padding: 66px 0 60px 80px;
}

.footer-con .upper_portion .footer-logo img {
  width: 145px;
}

.footer-con .upper_portion p {
  font-weight: 700;
  word-spacing: 3.4px;
  padding-left: 105px;
  text-transform: capitalize;
  color: var(--e-global-color-white);
  font-family: "Playfair Display", serif;
}

.footer-con .upper_portion form {
  float: left;
  width: 45.5%;
}

.footer-con .upper_portion .form-group {
  padding-left: 42px;
}

.footer-con .upper_portion input {
  font-size: 15px;
  line-height: 14px;
  font-weight: 400;
  padding: 18px 26px;
  width: 100%;
  position: relative;
  color: var(--e-global-color-text);
  background-color: var(--e-global-color-white);
  border: 1px solid transparent;
  border-radius: 8px;
  overflow: visible;
  outline: none;
}

.footer-con .upper_portion input:focus {
  border: 1px solid var(--e-global-color-accent);
}

.footer-con .upper_portion button {
  font-size: 19px;
  width: 55px;
  height: 47px;
  top: 4px;
  right: 4px;
  border: none;
  float: right;
  position: absolute;
  color: var(--e-global-color-white);
  background-color: var(--e-global-color-accent);
  border-radius: 6px;
  overflow: visible;
  outline: none;
  transition: all 0.8s ease-in-out;
}

.footer-con .upper_portion button:hover {
  background-color: var(--e-global-color-secondary);
}

.footer-con .middle_portion {
  padding: 45px 0px
}

.footer-con .middle_portion .logo-content h4 {
  margin-bottom: 14px;
}

.footer-con .middle_portion h5 {
  margin-bottom: 20px;
  color: var(--e-global-color-white);
}

.footer-con .middle_portion p {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 18px;
  padding-right: 40px;
  color: var(--e-global-color-light-grayish-red);
}

.footer-con .middle_portion .social-icons li {
  margin: 0 4px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.footer-con .middle_portion .social-icons a {
  margin-left: 0 !important;
}

.footer-con .middle_portion .social-icons i {
  font-size: 14px;
  height: 44px;
  width: 44px;
  line-height: 42px;
  border-radius: 100px;
  text-align: center;
  color: var(--e-global-color-white);
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease-in-out;
}

.footer-con .middle_portion .social-icons i:hover {
  transform: translateY(-5px);
  color: var(--e-global-color-white);
  background-color: var(--e-global-color-accent);
  border: 1px solid var(--e-global-color-accent);
}

.footer-con .middle_portion .social-icons li:first-child {
  margin-left: 0;
}

.footer-con .middle_portion .social-icons li:last-child {
  margin-right: 0;
}

.footer-con .middle_portion .links {
  padding-left: 6px;
}

.footer-con .middle_portion .use-link {
  padding-left: 26px;
}

.footer-con .middle_portion li {
  margin-bottom: 6px;
}

.footer-con .middle_portion li:last-child {
  margin-bottom: 0 !important;
}

.footer-con .middle_portion li a {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  margin-left: 3px;
  text-decoration: none;
  color: var(--e-global-color-light-grayish-red);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.footer-con .middle_portion li p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 0;
  color: var(--e-global-color-light-grayish-red);
  transition: all 0.3s ease-in-out;
}

.footer-con .middle_portion li i {
  font-size: 10px;
  line-height: 10px;
  color: var(--e-global-color-white);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.footer-con .middle_portion li:hover p,
.footer-con .middle_portion li:hover a,
.footer-con .middle_portion li:hover i {
  color: var(--e-global-color-accent);
}

.footer-con .middle_portion .icon .text {
  position: relative;
  margin-bottom: 11px;
  padding-left: 30px;
  display: inline-block;
}

.footer-con .middle_portion .icon i {
  position: absolute;
  font-size: 14px;
  line-height: 14px;
  left: 0;
  top: 6px;
}

.footer-con .middle_portion .icon a {
  margin-left: 0 !important;
}

.footer-con .copyright p {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  padding: 25px 0;
  text-align: center;
  color: var(--e-global-color-light-grayish-red);
  border-top: 1px solid rgb(255 255 255 / 15%);
}

/* Sub banner */

.sub_banner .sub_banner_con {
  padding: 142px 0 154px;
  text-align: center;
  background: var(--e-global-color-black);
}

.sub_banner .sub_banner_con::before {
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 55%;
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.sub_banner .sub_banner_con h1 {
  font-size: 50px;
  line-height: 60px;
  margin-bottom: 30px;
}

.sub_banner .sub_banner_con p {
  margin-bottom: 40px;
}

.sub_banner .sub_banner_con .box {
  padding: 10px 24px 12px;
  text-align: center;
  border-radius: 6px;
  display: inline-block;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 40%);
  transition: all 0.3s ease-in-out;
}

.sub_banner .sub_banner_con .box span {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  display: inline-block;
  color: var(--e-global-color-white);
  transition: all 0.3s ease-in-out;
}

.sub_banner .sub_banner_con .box a span:hover {
  color: var(--e-global-color-accent);
}

.sub_banner .sub_banner_con .box .arrow {
  font-size: 10px;
  margin: 0 10px;
  display: inline-block;
  color: var(--e-global-color-white);
}

/* Contact Page */

/* Contact info */

.contactinfo-con {
  padding: 135px 0;
}

.contactinfo-con .contactinfo_content h6 {
  margin-bottom: 10px;
}

.contactinfo-con .contactinfo_content h2 {
  margin-bottom: 45px;
}

.contactinfo-con .contact-box {
  padding: 46px 50px;
  text-align: center;
  background-color: var(--e-global-color-white);
  border: 1px solid var(--e-global-color-white);
  box-shadow: 0 1px 94px 1px rgb(0 0 0 / 3%);
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}

.contactinfo-con .contact-box:hover {
  border: 1px solid var(--e-global-color-accent);
}

.contactinfo-con .contact-box .icon {
  height: 60px;
  margin-bottom: 26px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.contactinfo-con .contact-box:hover .icon {
  transform: translateY(-5px);
}

.contactinfo-con .contact-box h4 {
  margin-bottom: 14px;
}

.contactinfo-con .contact-box p,
.contactinfo-con .contact-box a {
  margin-bottom: 15px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.contactinfo-con .contact-box a:hover {
  color: var(--e-global-color-accent);
}

/* Contact Form */

.contactform-con {
  background-color: var(--e-global-color-accent);
}

.contactform-con::before {
  content: "";
  width: 49%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background-image: url(../images/contact-leftbackground.jpg);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

.contactform-con .contact-bottomimage {
  position: absolute;
  right: 0;
  bottom: 0;
}

.contactform-con .contact-bottomimage img {
  opacity: 10%;
}

.contactform-con .contact_wrapper {
  display: none;
}

.contactform-con .contact_content {
  padding: 135px 0 135px 40px;
  position: relative;
  z-index: 1;
}

.contactform-con .contact_content h6 {
  margin-bottom: 10px;
}

.contactform-con .contact_content h2 {
  margin-bottom: 38px;
}

.contactform-con .contact_content .form-group {
  margin-bottom: 22px;
}

.contactform-con .contact_content .input1 {
  float: left;
  margin-right: 22px;
}

.contactform-con .contact_content input,
.contactform-con .contact_content textarea {
  font-size: 15px;
  line-height: 14px;
  font-weight: 400;
  height: 58px;
  width: 236px;
  padding: 10px 10px 10px 26px;
  color: var(--e-global-color-text);
  background-color: var(--e-global-color-white);
  border: 1px solid var(--e-global-color-white);
  font-family: "Rajdhani", sans-serif;
  border-radius: 8px;
  overflow: visible;
  outline: none;
}

.contactform-con .contact_content textarea {
  height: 118px;
  width: 100% !important;
  padding: 16px 26px;
  resize: none;
  outline: none;
  overflow: auto;
}

.contactform-con .contact_content .message {
  margin-bottom: 22px;
}

.contactform-con .contact_content input:focus,
.contactform-con .contact_content textarea:focus {
  box-shadow: 2px 3px 42px rgb(55 225 197 / 16%);
  border: 1px solid var(--e-global-color-secondary);
}

.contactform-con .contact_content .submit_now {
  font-size: 17px;
  line-height: 18px;
  font-weight: 700;
  padding: 20px 35px;
  text-align: center;
  display: inline-block;
  position: relative;
  border-radius: 8px;
  color: var(--e-global-color-white);
  background-color: var(--e-global-color-secondary);
  font-family: "DM Sans", sans-serif;
  transition: all 0.8s ease-in-out;
  text-align: center;
  outline: none;
  border-style: none;
}

.contactform-con .contact_content .submit_now:hover {
  color: var(--e-global-color-secondary);
  background-color: var(--e-global-color-white);
}

.contactform-con .contact_content .submit_now i {
  font-size: 12px;
  margin-left: 22px;
  color: var(--e-global-color-white);
  transition: all 0.8s ease-in-out;
}

.contactform-con .contact_content .submit_now:hover i {
  transform: translateX(4px);
  color: var(--e-global-color-secondary);
}

.contactform-con .contact_content input::placeholder,
.contactform-con .contact_content textarea::placeholder {
  color: var(--e-global-color-text);
}

.contactform-con .contact_content form span {
  font-size: 16px;
  line-height: 14px;
  font-weight: 400;
  margin-top: 5px;
  position: absolute;
  display: block;
  color: var(--e-global-color-black);
}

/* Map */

.map-con {
  padding: 140px 0;
}

.map-con iframe {
  width: 100%;
  height: 547px;
  border-radius: 20px;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.map-con iframe:hover {
  filter: none;
}

/* Team Page */

/* Team */

.team-con {
  padding: 135px 0 80px;
}

.team-con .team_content h6 {
  margin-bottom: 10px;
}

.team-con .team_content h2 {
  margin-bottom: 50px;
}

.team-con .team-box {
  margin-bottom: 65px;
  position: relative;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.team-con .team-box figure {
  width: 314px;
  height: 314px;
  margin: 0 auto 28px;
  border-radius: 100%;
  overflow: hidden;
}

.team-con .team-box figure img {
  transition: all 0.3s ease-in-out;
}

.team-con .team-box:hover figure img {
  transform: scale(1.1);
}

.team-con .team-box h4 {
  font-size: 21px;
  margin-bottom: 10px;
}

.team-con .team-box span {
  display: block;
  margin-bottom: 16px;
}

.team-con .team-box li {
  margin: 0 3px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.team-con .team-box i {
  font-size: 15px;
  height: 45px;
  width: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 100%;
  color: var(--e-global-color-white);
  background-color: var(--e-global-color-accent);
  transition: all 0.3s ease-in-out;
}

.team-con .team-box li:hover {
  transform: translateY(-5px);
}

.team-con .team-box li:first-child {
  margin-left: 0;
}

.team-con .team-box li:last-child {
  margin-right: 0;
}

.team-con .team-box:hover i {
  background-color: var(--e-global-color-secondary);
}

/* Destination Page */

/* Place */

.destinationpage-place {
  padding-bottom: 110px;
}

.destinationpage-place .place_content h2 {
  margin-bottom: 35px;
}

.destinationpage-place .place-box {
  margin-bottom: 30px;
  box-shadow: 0 1px 94px 1px rgb(0 0 0 / 3%);
}

.destinationpage-place .nav-tabs {
  width: 54%;
  margin: 0 auto 52px;
  justify-content: center;
  border-bottom: 2px solid #ebebeb;
}

.destinationpage-place .tabs-box .nav-tabs li a {
  font-size: 17px;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  color: var(--e-global-color-secondary);
  text-decoration: none;
  position: relative;
  top: 2px;
  padding: 22px 35px;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease-in-out;
}

.destinationpage-place .tabs-box .nav-tabs li a:hover {
  color: var(--e-global-color-accent);
}

.destinationpage-place .tabs-box .nav-tabs > li > a.active {
  color: var(--e-global-color-accent);
  border-bottom: 2px solid var(--e-global-color-accent);
}

/* Faq Page */

/* Faq */

.faq-con {
  padding: 136px 0 140px;
}

.faq-con .faq_content h6 {
  margin-bottom: 10px;
}

.faq-con .faq_content h2 {
  margin-bottom: 45px;
}

.faq-con h5 {
  font-size: 17px;
  line-height: 18px;
  margin-bottom: 0;
}

.faq-con .accordion-card {
  margin-bottom: 20px;
  width: 100%;
  border-radius: 10px;
  background-color: var(--e-global-color-white);
  box-shadow: 0 1px 94px 1px rgb(0 0 0 / 5%);
}

.faq-con .accordion-card:last-child {
  margin-bottom: 0;
}

.faq-con .accordion-card .btn-link h5 {
  color: var(--e-global-color-accent);
}

.faq-con .accordion-card .collapsed h5 {
  color: var(--e-global-color-secondary);
}

.faq-con .accordion-card:has(.btn-link) {
  border: 1px solid var(--e-global-color-accent);
}

.faq-con .accordion-card:has(.collapsed) {
  border: 1px solid var(--e-global-color-white);
}

.faq-con .accordian-inner .card-header {
  padding: 0;
  margin-bottom: 0;
  background: none;
  border: none;
}

.faq-con .accordian-inner .card-header a {
  padding: 22px 26px;
}

.faq-con .accordian-inner .card-body {
  padding: 0 26px 20px;
}

.faq-con .accordion-card a.btn.btn-link:focus {
  outline: none;
  box-shadow: none;
}

.faq-con .accordian-inner .accordion-card i {
  position: absolute;
  right: 20px;
  margin: -6px 0;
}

.faq-con .accordian-inner .accordion-card .btn {
  text-decoration: none;
  text-align: left;
  display: block;
  border-radius: 10px;
}

.faq-con .accordion-card a.btn.btn-link {
  position: relative;
}

.faq-con .accordion-card .btn-link:before {
  content: "\f062";
  font-size: 14px;
  top: 20px;
  right: 22px;
  position: absolute;
  font-family: 'FontAwesome';
  color: var(--e-global-color-accent);
}

.faq-con .accordion-card .collapsed:before {
  content: "\f061";
  color: var(--e-global-color-secondary);
}

/* About Page */

/* About */

.aboutpage-con {
  padding: 138px 0 145px;
}

.aboutpage-con .about_wrapper .about-image {
  position: relative;
  top: 0;
  left: 0;
}

.aboutpage-con .about_wrapper .about-image img {
  border-radius: 100%;
}

.aboutpage-con .about_wrapper .about-locationimage {
  position: absolute;
  bottom: 6px;
  right: 70px;
}

.aboutpage-con .about_content h6 {
  margin-bottom: 10px;
}

.aboutpage-con .about_content h2 {
  margin-bottom: 20px;
  font-size: 27px;
}

.aboutpage-con .about_content .text {
  margin-bottom: 30px;
}

.aboutpage-con .about_content ul {
  margin-bottom: 30px;
  display: inline-block;
}

.aboutpage-con .about_content ul li {
  position: relative;
  margin-bottom: 8px;
}

.aboutpage-con .about_content ul li i {
  font-size: 8px;
  height: 16px;
  width: 16px;
  line-height: 13px;
  top: 4px;
  left: 0;
  position: absolute;
  text-align: center;
  border-radius: 100%;
  color: var(--e-global-color-accent);
  border: 2px solid var(--e-global-color-accent);
}

.aboutpage-con .about_content ul li p {
  font-weight: 500;
  padding-left: 32px;
  color: var(--e-global-color-secondary);
}

.aboutpage-con .about_content .all_button {
  padding: 20px 38px;
}

/* Activity */

.activity-con {
  background-color: var(--e-global-color-accent);
}

.activity-con::before {
  content: "";
  width: 49%;
  height: 100%;
  right: 0;
  top: 0;
  position: absolute;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

.activity-con .activity-bottomimage {
  position: absolute;
  left: 0;
  bottom: 0;
}

.activity-con .activity-bottomimage img {
  opacity: 10%;
}

.activity-con .activity_wrapper {
  display: none;
}

.activity-con .activity_content {
  position: relative;
  z-index: 1;
  padding: 130px 70px 135px 0;
}

.activity-con .activity_content h6 {
  margin-bottom: 10px;
}

.activity-con .activity_content h2 {
  margin-bottom: 20px;
  font-size: 27px;
}

.activity-con .activity_content .text {
  margin-bottom: 50px;
}

.activity-con .activity_content li {
  width: 134px;
  height: 139px;
  padding: 28px 32px;
  margin: 0 8px;
  border-radius: 8px;
  display: inline-block;
  background-color: var(--e-global-color-white);
  border-bottom: 2px solid var(--e-global-color-secondary);
}

.activity-con .activity_content li:hover {
  box-shadow: 0 1px 94px 1px rgb(0 0 0 / 15%);
}

.activity-con .activity_content li .value {
  font-size: 35px;
  line-height: 36px;
  font-weight: 700;
  display: block;
  margin-bottom: 6px;
  color: var(--e-global-color-accent);
}

.activity-con .activity_content li:first-child {
  margin-left: 0;
}

.activity-con .activity_content li:last-child {
  margin-right: 0;
}

/* Explore */

.aboutexplore-con {
  padding: 135px 0 140px;
}

.aboutexplore-con .explore_content h6 {
  margin-bottom: 10px;
}

.aboutexplore-con .explore_content h2 {
  margin-bottom: 45px;
}

.aboutexplore-con .explore-box {
  position: relative;
  transition: all 0.3s ease-in-out;
}

.aboutexplore-con .explore-box .image {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.aboutexplore-con .explore-box .image::before {
  content: "";
  position: absolute;
  opacity: 50%;
  width: 100%;
  height: 100%;
  bottom: 0;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../images/explore-fadeimage.png);
  transition: all 0.3s ease-in-out;
}

.aboutexplore-con .explore-box:hover .image::before {
  display: none;
}

.aboutexplore-con .explore-box:hover .image {
  opacity: 1;
  background-color: var(--e-global-color-black);
}

.aboutexplore-con .explore-box .image img {
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
}

.aboutexplore-con .explore-box:hover .image img {
  opacity: 70%;
  transform: scale(1.1);
}

.aboutexplore-con .explore-box .rating {
  left: 20px;
  top: 18px;
  padding: 7px 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: absolute;
  background-color: rgb(0 0 0 / 35%);
}

.aboutexplore-con .explore-box .rating i {
  font-size: 8px;
  margin-right: 4px;
  color: var(--e-global-color-star-yellow);
}

.aboutexplore-con .explore-box .rating span {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
}

.aboutexplore-con .explore-box .content {
  position: absolute;
  bottom: 30px;
  left: 20px;
}

.aboutexplore-con .explore-box .content .place {
  margin-bottom: 6px;
}

.aboutexplore-con .explore-box .content i {
  font-size: 10px;
  margin-right: 2px;
  color: var(--e-global-color-white);
}

.aboutexplore-con .explore-box .content span {
  font-size: 11px;
  line-height: 12px;
  font-weight: 500;
}

.aboutexplore-con .owl-carousel .owl-nav {
  display: none !important;
  margin-top: 54px !important;
  position: relative;
  text-align: center;
}

.aboutexplore-con .owl-carousel .owl-prev,
.aboutexplore-con .owl-carousel .owl-next {
  font-size: 17px !important;
  width: 57px;
  height: 57px;
  line-height: 57px !important;
  border-radius: 100% !important;
  position: relative;
  text-align: center;
  color: var(--e-global-color-secondary) !important;
  background-color: transparent !important;
  border: 1px solid var(--e-global-color-secondary) !important;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.aboutexplore-con .owl-carousel .owl-prev {
  left: -8px;
}

.aboutexplore-con .owl-carousel .owl-next {
  right: -8px;
}

.aboutexplore-con .owl-carousel .owl-prev:hover,
.aboutexplore-con .owl-carousel .owl-next:hover {
  color: var(--e-global-color-white) !important;
  background-color: var(--e-global-color-accent) !important;
  border: 1px solid var(--e-global-color-accent) !important;
}

/* Home 2 */

/* Banner */

.travel_banner_outer {
  background-color: var(--e-global-color-light-grayish-orange);
}

.banner2-con {
  padding: 70px 0 165px;
  background-color: var(--e-global-color-light-grayish-orange);
}

.banner2-con:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 12%;
  top: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../images/banner2-backgroundimage.jpg);
}

.banner2-con .banner_content h3 {
  padding-left: 54px;
  margin-bottom: 14px;
  letter-spacing: 2.0px;
  word-spacing: 2.0px;
  position: relative;
}

.banner2-con .banner_content h3::before {
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto 0;
  width: 42px;
  height: 2px;
  position: absolute;
  background-color: var(--e-global-color-accent);
}

.banner2-con .banner_content h1 {
  font-size: 80px;
  line-height: 96px;
  margin-bottom: 40px;
}

.banner2-con .banner_content h1 span {
  color: var(--e-global-color-accent);
}

.banner2-con .banner_content .all_button {
  padding: 21px 37px;
}

.banner2-con .banner_wrapper .image {
  overflow: hidden;
  border-radius: 20px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.banner2-con .banner_wrapper .banner-image1 {
  position: relative;
  left: 20px;
  top: 0;
}

.banner2-con .banner_wrapper .banner-image2 {
  position: relative;
  left: 20px;
  bottom: -30px;
}

.banner2-con .banner_wrapper .banner-image3 {
  position: absolute;
  right: -152px;
  top: 95px;
}

.banner2-con .banner_wrapper .banner-image3::before {
  content: "";
  right: -68px;
  bottom: -68px;
  width: 238px;
  height: 238px;
  border-radius: 100%;
  position: absolute;
  z-index: -1;
  background-color: var(--e-global-color-white);
}

.banner2-con .banner_wrapper .image img {
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}

.banner2-con .banner_wrapper .image:hover img {
  transform: scale(1.1);
}

.banner2-con .banner_wrapper .box {
  left: -140px;
  bottom: 88px;
  width: 258px;
  height: 73px;
  padding: 10px 15px;
  border-radius: 36px;
  display: flex;
  align-items: center;
  position: absolute;
  background-color: var(--e-global-color-white);
  box-shadow: 2px 20px 29px 20px rgb(0 0 0 / 3%);
}

.banner2-con .banner_wrapper .box .text {
  padding-left: 10px;
}

.banner2-con .banner_wrapper .box .value {
  font-size: 19px;
  line-height: 24px;
  font-weight: 700;
  display: block;
  margin-bottom: 0;
  color: var(--e-global-color-accent);
}

.banner2-con .banner_wrapper .box .review {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: var(--e-global-color-text);
}

.banner2-con .banner_wrapper .box figure {
  margin: 0 -12px;
  display: inline-block;
}

.banner2-con .banner_wrapper .box figure:first-child {
  margin-left: 0;
}

.banner2-con .banner_wrapper .box figure:last-child {
  margin-right: 0;
}

.banner2-con .banner_wrapper .box img {
  width: 48px;
  border-radius: 100%;
  border: 2px solid var(--e-global-color-white);
}

/* Benefit */

.benefit-con {
  padding: 135px 0 140px;
}

.benefit-con .benefit_content h6 {
  margin-bottom: 10px;
}

.benefit-con .benefit_content h2 {
  margin-bottom: 45px;
}

.benefit-con .benefit-box {
  padding: 40px 50px 45px;
  background-color: var(--e-global-color-white);
  border-bottom: 2px solid var(--e-global-color-accent);
  box-shadow: 0 1px 94px 1px rgb(0 0 0 / 3%);
  text-align: center;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}

.benefit-con .benefit-box .icon {
  min-height: 60px;
  margin-bottom: 28px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.benefit-con .benefit-box:hover .icon {
  transform: translateY(-5px);
}

.benefit-con .benefit-box h4 {
  margin-bottom: 14px;
}

/* About */

.about2-con {
  background-color: var(--e-global-color-accent);
}

.about2-con::before {
  content: "";
  width: 49%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background-image: url(../images/about2-leftbackground.jpg);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

.about2-con .about-bottomimage {
  position: absolute;
  right: 0;
  bottom: 0;
}

.about2-con .about-bottomimage img {
  opacity: 10%;
}

.about2-con .about_wrapper .about-leftbackground {
  display: none;
}

.about2-con .about_wrapper .about-locationimage {
  position: absolute;
  right: -60px;
  top: 290px;
}

.about2-con .about_content {
  position: relative;
  z-index: 1;
  padding: 135px 0 135px 78px;
}

.about2-con .about_content h6 {
  margin-bottom: 10px;
}

.about2-con .about_content h2 {
  margin: 0 -15px 20px 0;
}

.about2-con .about_content .text {
  margin-bottom: 30px;
}

.about2-con .about_content ul {
  margin-bottom: 40px;
  display: inline-block;
}

.about2-con .about_content ul li {
  position: relative;
  margin-bottom: 8px;
}

.about2-con .about_content ul li i {
  font-size: 8px;
  height: 16px;
  width: 16px;
  line-height: 13px;
  top: 4px;
  left: 0;
  position: absolute;
  text-align: center;
  border-radius: 100%;
  color: var(--e-global-color-white);
  border: 2px solid var(--e-global-color-white);
}

.about2-con .about_content ul li p {
  font-weight: 500;
  padding-left: 32px;
  color: var(--e-global-color-white);
}

.about2-con .about_content .all_button {
  padding: 20px 38px;
  background-color: var(--e-global-color-secondary);
  transition: all 0.8s ease-in-out;
}

.about2-con .about_content .all_button i {
  transition: all 0.8s ease-in-out;
}

.about2-con .about_content .all_button:hover {
  color: var(--e-global-color-secondary);
  background-color: var(--e-global-color-white);
}

.about2-con .about_content .all_button:hover i {
  color: var(--e-global-color-secondary);
}

/* Making */

.making-con {
  background-color: var(--e-global-color-light-grayish-orange);
}

.making-con:before {
  content: "";
  position: absolute;
  opacity: 12%;
  width: 100%;
  height: 100%;
  top: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../images/making-backgroundimage.jpg);
}

.making-con .making_content {
  padding: 135px 0 115px;
}

.making-con .making_content h6 {
  margin-bottom: 10px;
}

.making-con .making_content h2 {
  margin-bottom: 40px;
}

.making-con .making-box {
  padding: 40px 30px 35px;
  margin-bottom: 25px;
  background-color: var(--e-global-color-white);
  border-bottom: 2px solid var(--e-global-color-accent);
  box-shadow: 0 1px 94px 1px rgb(0 0 0 / 3%);
  text-align: center;
  border-radius: 20px;
  transition: all 0.3s ease-in-out;
}

.making-con .box1 {
  padding: 40px 25px 35px;
}

.making-con .making-box .icon {
  height: 60px;
  margin-bottom: 20px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.making-con .making-box:hover .icon {
  transform: translateY(-5px);
}

.making-con .making-box h4 {
  font-size: 17px;
  line-height: 22px;
  margin-bottom: 14px;
}

.making-con .making-image {
  position: absolute;
  bottom: 0;
  right: -300px;
}

.making-con .making-image:before {
  content: "";
  position: absolute;
  width: 670px;
  height: 670px;
  top: 80px;
  right: 80px;
  border-radius: 100%;
  z-index: -1;
  background-color: var(--e-global-color-white);
}

/* Join */

.join2-con:before {
  opacity: 60%;
  background-image: url(../images/join2-backgroundimage.jpg);
}

/* Testimonial */

.testimonial2-con {
  padding: 135px 0 140px;
}

.testimonial2-con .testimonial_content h6 {
  margin-bottom: 10px;
}

.testimonial2-con .testimonial_content h2 {
  margin-bottom: 45px;
}

.testimonial2-con .testimonial-box {
  padding: 50px 30px 45px 35px;
  border-radius: 20px;
  background-color: var(--e-global-color-white);
  border-bottom: 2px solid var(--e-global-color-accent);
  box-shadow: 0 1px 94px 1px rgb(0 0 0 / 3%) inset;
}

.testimonial2-con .testimonial-box .testimonial-quoteimage {
  margin-bottom: 20px;
}

.testimonial2-con .testimonial-box p {
  margin-bottom: 24px;
}

.testimonial2-con .review-content {
  padding-left: 16px;
  display: flex;
}

.testimonial2-con .review-content .testimonial-personimage {
  position: relative;
  top: 2px;
  left: -15px;
}

.testimonial2-con .review-content .testimonial-personimage img {
  width: 54px !important;
  border-radius: 100px;
}

.testimonial2-con .testimonial-box ul {
  margin-bottom: 6px;
}

.testimonial2-con .testimonial-box ul li {
  display: inline-block;
}

.testimonial2-con .testimonial-box ul li i {
  font-size: 12px;
  line-height: 12px;
  color: var(--e-global-color-light-yellow);
}

.testimonial2-con .review-content .name {
  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
  display: block;
  font-family: "Playfair Display", serif;
  color: var(--e-global-color-secondary);
}

.testimonial2-con .review-content .review {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: var(--e-global-color-text);
}

.testimonial2-con .owl-carousel .owl-item img {
  width: auto !important;
}

.testimonial2-con .owl-carousel .owl-nav {
  display: block !important;
  margin-top: 52px !important;
  position: relative;
  text-align: center;
}

.testimonial2-con .owl-carousel .owl-prev,
.testimonial2-con .owl-carousel .owl-next {
  font-size: 17px !important;
  width: 57px;
  height: 57px;
  line-height: 57px !important;
  border-radius: 100% !important;
  position: relative;
  text-align: center;
  color: var(--e-global-color-secondary) !important;
  background-color: transparent !important;
  border: 1px solid var(--e-global-color-secondary) !important;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.testimonial2-con .owl-carousel .owl-prev {
  left: -4px;
}

.testimonial2-con .owl-carousel .owl-next {
  right: -4px;
}

.testimonial2-con .owl-carousel .owl-prev:hover,
.testimonial2-con .owl-carousel .owl-next:hover {
  color: var(--e-global-color-white) !important;
  background-color: var(--e-global-color-accent) !important;
  border: 1px solid var(--e-global-color-accent) !important;
}

/* Home 3 */

/* Banner */

.banner3-con {
  padding: 188px 0 220px;
  background-color: var(--e-global-color-black);
}

.banner3-con:before {
  content: "";
  position: absolute;
  opacity: 75%;
  width: 100%;
  height: 100%;
  top: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../images/banner3-backgroundimage.jpg);
}

.banner3-con .top-btn {
  position: absolute;
  z-index: 1;
  top: 156px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: 0.3s ease-in-out;
}

.banner3-con .top-btn:hover {
  transform: translateY(-5px);
}

.banner3-con ul {
  position: absolute;
  left: -280px;
  top: 128px;
  z-index: 2;
}

.banner3-con ul li {
  margin: 15px 0;
  transition: all 0.3s ease-in-out;
}

.banner3-con ul li:first-child {
  margin-top: 0;
}

.banner3-con ul li:last-child {
  margin-bottom: 0;
}

.banner3-con ul li i {
  font-size: 15px;
  width: 46px;
  height: 46px;
  line-height: 46px;
  border-radius: 100%;
  text-align: center;
  color: var(--e-global-color-white);
  background-color: transparent;
  border: 1px solid rgb(255 255 255 / 50%);
  transition: all 0.3s ease-in-out;
}

.banner3-con ul li a:hover i {
  background-color: var(--e-global-color-accent);
  border: 1px solid var(--e-global-color-accent);
}

.banner3-con .banner_content {
  position: relative;
  z-index: 1;
}

.banner3-con .banner_content h3 {
  letter-spacing: 2.0px;
  margin-bottom: 18px;
}

.banner3-con .banner_content h3 span {
  top: -4px;
  font-size: 27px;
  padding: 0 20px;
  position: relative;
}

.banner3-con .banner_content h1 {
  margin-bottom: 26px;
}

.banner3-con .banner_content p {
  padding: 0 95px;
  margin-bottom: 36px;
}

.banner3-con .banner_content .all_button {
  padding: 21px 37px;
}

.banner3-con .banner_content .all_button:hover,
.banner3-con .banner_content .all_button:hover i {
  color: var(--e-global-color-secondary);
}

.banner3-con .banner_content .all_button:hover {
  background-color: var(--e-global-color-white);
}

.banner3-con .banner_content ul {
  display: none;
}

/* About */

.about3-con {
  padding: 140px 0 200px;
}

.about3-con .about-bottomimage {
  position: absolute;
  right: 0;
  bottom: 0;
}

.about3-con .about-bottomimage img {
  opacity: 10%;
}

.about3-con .about_wrapper .about-image1 {
  position: relative;
  left: 0;
  top: 0;
}

.about3-con .about_wrapper .about-image1::before {
  content: "";
  position: absolute;
  width: 230px;
  height: 230px;
  left: -62px;
  bottom: -62px;
  border-radius: 100%;
  background-color: var(--e-global-color-accent);
  z-index: -1;
}

.about3-con .about_wrapper .about-image2 {
  position: absolute;
  right: -56px;
  bottom: -102px;
}

.about3-con .about_wrapper .about-image2 img {
  border: 8px solid var(--e-global-color-white);
}

.about3-con .about_wrapper .image img {
  border-radius: 20px;
}

.about3-con .about_wrapper .video-icon {
  position: absolute;
  width: 101px;
  height: 101px;
  line-height: 96px;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 100%;
  text-align: center;
  background-color: rgb(0 0 0 / 45%);
  border: 2px solid rgb(255 255 255 / 50%);
}

.about3-con .about_wrapper .video-icon img {
  border-radius: 0 !important;
  border: none !important;
  width: auto !important;
}

.about3-con .about_content {
  z-index: 1;
  position: relative;
  padding: 116px 0 0 48px;
}

.about3-con .about_content h6 {
  margin-bottom: 10px;
}

.about3-con .about_content h2 {
  margin-bottom: 20px;
}

.about3-con .about_content .text {
  margin-bottom: 30px;
}

.about3-con .about_content .propertise {
  margin-bottom: 35px;
}

.about3-con .about_content ul {
  display: inline-block;
}

.about3-con .about_content .list1 {
  padding-right: 22px;
}

.about3-con .about_content ul li {
  position: relative;
  margin-bottom: 8px;
}

.about3-con .about_content ul li i {
  font-size: 8px;
  height: 16px;
  width: 16px;
  line-height: 13px;
  top: 4px;
  left: 0;
  position: absolute;
  text-align: center;
  border-radius: 100%;
  color: var(--e-global-color-accent);
  border: 2px solid var(--e-global-color-accent);
}

.about3-con .about_content ul li p {
  font-weight: 500;
  padding-left: 30px;
  color: var(--e-global-color-secondary);
}

.about3-con .about_content .all_button {
  padding: 20px 38px;
}

/* Service */

.service3-con {
  background-color: var(--e-global-color-light-grayish-orange);
}

.service3-con:before {
  content: "";
  position: absolute;
  opacity: 12%;
  width: 100%;
  height: 100%;
  top: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../images/service3-backgroundimage.jpg);
}

/* Explore */

.explore3-con .container {
  max-width: 1710px;
  margin: 0 auto;
}

/* Activity */

.activity3-con::before {
  background-image: url(../images/activity3-rightbackground.jpg);
}

/* Testimonial */

.testimonial3-con {
  padding: 135px 0 140px;
  background-color: var(--e-global-color-light-grayish-orange);
}

.testimonial3-con:before {
  content: "";
  position: absolute;
  opacity: 12%;
  width: 100%;
  height: 100%;
  top: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../images/testimonial-backgroundimage.jpg);
}

.testimonial3-con .testimonial_content h6 {
  margin-bottom: 10px;
}

.testimonial3-con .testimonial_content h2 {
  margin-bottom: 45px;
}

.testimonial3-con .testimonial-box {
  padding: 44px 50px 44px 38px;
  border-radius: 20px;
  background-color: var(--e-global-color-white);
  border-bottom: 2px solid var(--e-global-color-accent);
}

.testimonial3-con .testimonial-box .testimonial-personimage {
  width: 19%;
  float: left;
}

.testimonial3-con .testimonial-box .testimonial-personimage img {
  border-radius: 100%;
}

.testimonial3-con .testimonial-box .content {
  width: 81%;
  padding-left: 25px;
  display: inline-block;
}

.testimonial3-con .testimonial-box p {
  margin-bottom: 10px;
}

.testimonial3-con .testimonial-box ul {
  margin-bottom: 10px;
}

.testimonial3-con .testimonial-box ul li {
  display: inline-block;
}

.testimonial3-con .testimonial-box ul li i {
  font-size: 15px;
  line-height: 14px;
  color: var(--e-global-color-light-yellow);
}

.testimonial3-con .testimonial-box .name {
  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
  display: block;
  font-family: "Playfair Display", serif;
  color: var(--e-global-color-secondary);
}

.testimonial3-con .testimonial-box .review {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: var(--e-global-color-text);
}

.testimonial3-con .owl-carousel .owl-nav {
  display: block !important;
  margin-top: 52px !important;
  position: relative;
  text-align: center;
}

.testimonial3-con .owl-carousel .owl-prev,
.testimonial3-con .owl-carousel .owl-next {
  font-size: 17px !important;
  width: 57px;
  height: 57px;
  line-height: 57px !important;
  border-radius: 100% !important;
  position: relative;
  text-align: center;
  color: var(--e-global-color-secondary) !important;
  background-color: transparent !important;
  border: 1px solid var(--e-global-color-secondary) !important;
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.testimonial3-con .owl-carousel .owl-prev {
  left: -4px;
}

.testimonial3-con .owl-carousel .owl-next {
  right: -4px;
}

.testimonial3-con .owl-carousel .owl-prev:hover,
.testimonial3-con .owl-carousel .owl-next:hover {
  color: var(--e-global-color-white) !important;
  background-color: var(--e-global-color-accent) !important;
  border: 1px solid var(--e-global-color-accent) !important;
}

/* For place pages */

/* Project PopUp Style */

.project_modal .modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow: hidden;
  outline: 0;
}

.project_modal .fade {
  opacity: 0;
  transition: opacity .15s linear;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.project_modal .fade.show {
  opacity: 1;
  background-color: transparent;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

.project_modal .modal.fade .modal-dialog {
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  transform: translate(0, -25%);
}

.project_modal .modal-dialog {
  max-width: 48%;
  margin: 1.75rem auto;
  position: relative;
  width: auto;
  pointer-events: none;
}

.project_modal .modal.show .modal-dialog {
  transform: translate(0, 0);
}

.project_modal .modal.fade .modal-dialog {
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
}

.project_modal .modal-content {
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
  background-color: var(--e-global-color-white);
  box-shadow: 1px 21px 46px rgb(0 0 100 / 25%);
  border-radius: 0;
  border: none;
}

.project_modal .modal-header {
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 16px;
  border-bottom: none;
}

.project_modal .btn-close:not(:disabled):not(.disabled) {
  cursor: pointer;
  opacity: 1 !important;
}

.project_modal .modal-header .btn-close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.project_modal .modal-header button {
  background: var(--e-global-color-accent);
  opacity: 1;
  position: absolute;
  right: 7px;
  top: 0;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  outline: none;
}

.project_modal .btn-close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
}

.project_modal button.btn-close {
  border: 0;
  -webkit-appearance: none;
}

.project_modal button {
  text-transform: none;
  overflow: visible;
}

.project_modal .modal-header button i {
  color: var(--e-global-color-white);
  font-size: 17px;
  line-height: 18px;
  padding: 5px 5px;
  outline: none;
}

.project_modal .fa-x:before {
  content: "\58";
}

.project_modal .blog-model-con .modal-body {
  overflow-y: auto;
  padding-top: 0;
}

.project_modal .modal-body {
  position: relative;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1rem;
  padding-left: 30px;
  padding-right: 30px;
}

.project_modal .modal-body .blog-box-item {
  display: flex;
  align-items: center;
  border-radius: 0;
}

.project_modal .blog-box-item {
  background: var(--white-color);
  overflow: hidden;
}

.project_modal .modal-body .blog-img {
  float: left;
  width: 100%;
}

.project_modal .modal-body .blog-img figure {
  position: relative;
}

.project_modal .img-fluid {
  width: 100%;
  border-radius: 20px;
}

.project_modal .modal-content .project_content {
  padding: 40px 0 40px 20px;
}

.project_modal .modal-content .project_content h4 {
  font-size: 21px;
  margin-bottom: 12px;
  color: var(--e-global-color-secondary);
}

.project_modal .modal-content .project_content .text {
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  display: block;
  margin-bottom: 20px;
  color: var(--e-global-color-text);
}

.project_modal .modal-content .project_content .properties {
  margin-bottom: 20px;
}

.project_modal .modal-content .project_content .properties ul {
  padding-left: 25px;
}

.project_modal .modal-content .project_content .properties li {
  margin-bottom: 8px;
  position: relative;
  color: var(--e-global-color-text);
}

.project_modal .modal-content .project_content .properties li:last-child {
  margin-bottom: 0;
}

.project_modal .modal-content .project_content .properties ul li .circle {
  font-size: 9px;
  width: 17px;
  height: 17px;
  line-height: 17px;
  top: 4px;
  left: -25px;
  text-align: center;
  border-radius: 100%;
  position: absolute;
  color: var(--e-global-color-white);
  background-color: var(--e-global-color-accent);
  transition: all 0.3s ease-in-out;
}

.project_modal .modal-content .project_content .all_button {
  padding: 20px 30px;
}

/* Service Detail */

.service_detail {
  padding: 130px 0 120px;
}

.service_detail .main-box {
  position: relative;
}

.service_detail .main-box .image {
  margin-bottom: 30px;
}

.service_detail .main-box .image img {
  width: 100%;
  border-radius: 20px;
}

.service_detail .main-box h3 {
  font-size: 23px;
  line-height: 30px;
}

.service_detail .main-box p {
  margin-bottom: 10px;
}

.service_detail .main-box .text2 {
  margin-bottom: 20px;
}

.service_detail .main-box .list {
  margin-bottom: 20px;
}

.service_detail .main-box .list li {
  margin-bottom: 6px;
  padding-left: 28px;
  line-height: 20px;
  font-weight: 500;
  position: relative;
  color: var(--e-global-color-text);
}

.service_detail .main-box .list li i {
  font-size: 9px;
  height: 16px;
  width: 16px;
  line-height: 17px;
  top: 2PX;
  left: 0;
  border-radius: 100px;
  position: absolute;
  text-align: center;
  display: inline-block;
  background-color: var(--e-global-color-accent);
  color: var(--e-global-color-white);
}

/* 404 Page */

.error_page {
  height: 100vh;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-color: var(--e-global-color-light-grayish-orange);
}

.error_page .error_content h1 {
  font-size: 140px;
  line-height: 140px;
  margin-bottom: 40px;
  color: var(--e-global-color-secondary);
}

.error_page .error_content .text {
  font-size: 36px;
  line-height: 40px;
  font-weight: 500;
  margin-bottom: 20px;
  color: var(--e-global-color-secondary);
}

.error_page .error_content .text2 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 30px;
  color: var(--e-global-color-text);
}

.error_page .error_content .back_home {
  font-size: 16px;
  line-height: 15px;
  font-weight: 700;
  padding: 21px 30px;
  text-align: center;
  border-radius: 5px;
  display: inline-block;
  position: relative;
  color: var(--e-global-color-white);
  background: var(--e-global-color-accent);
  transition: all 0.3s ease-in-out;
}

.error_page .error_content .back_home:hover {
  color: var(--e-global-color-white);
  background: var(--e-global-color-secondary);
}

.error_page .error_content .back_home i {
  font-size: 12px;
  margin-left: 24px;
  transition: all 0.3s ease-in-out;
  color: var(--e-global-color-white);
}

.error_page .error_content .back_home:hover i {
  transform: translateX(4px);
}

/* Coming Soon */

.comingsoon_outer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
  text-align: center;
  position: relative;
  background-color: var(--e-global-color-light-grayish-orange);
}

.comingsoon_outer .logo_outer {
  margin-bottom: 70px;
}

.comingsoon_outer .logo_outer img {
  width: 145px;
}

.comingsoon_outer .comingsoon_section {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}

.comingsoon_outer .sub_banner_content h1 {
  font-size: 90px;
  line-height: 92px;
  margin-bottom: 30px;
  color: var(--e-global-color-secondary);
}

.comingsoon_outer .sub_banner_content p {
  font-weight: 500;
  margin-bottom: 12px;
  color: var(--e-global-color-text);
}

.comingsoon_outer .sub_banner_content form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.comingsoon_outer .sub_banner_content .form-group {
  margin-bottom: 30px;
}

.comingsoon_outer .sub_banner_content input {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  padding: 14px 16px 16px;
  width: 540px;
  position: relative;
  color: var(--e-global-color-text);
  background-color: var(--e-global-color-white);
  box-shadow: 0 1px 94px 1px rgb(0 0 0 / 3%);
  border: 1px solid transparent;
  border-radius: 8px;
  overflow: visible;
  outline: none;
}

.comingsoon_outer .sub_banner_content button {
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  padding: 15px 30px 18px;
  height: 100%;
  top: 0;
  right: -5px;
  border-radius: 0 8px 8px 0;
  position: absolute;
  color: var(--e-global-color-white);
  background: var(--e-global-color-accent);
  border: 1px solid var(--e-global-color-accent);
  overflow: visible;
  outline: none;
  transition: all 0.3s ease-in-out;
}

.comingsoon_outer .sub_banner_content input:focus {
  border: 1px solid var(--e-global-color-accent);
}

.comingsoon_outer .sub_banner_content button:hover {
  color: var(--e-global-color-white);
  background: var(--e-global-color-secondary);
  border: 1px solid var(--e-global-color-secondary);
}

.comingsoon_outer .sub_banner_content .social-icon li {
  display: inline-block;
  margin: 0 4px;
}

.comingsoon_outer .sub_banner_content .social-icon li:first-child {
  margin-left: 0;
}

.comingsoon_outer .sub_banner_content .social-icon li:last-child {
  margin-right: 0;
}

.comingsoon_outer .sub_banner_content .social-icon a {
  transition: all 0.3s ease-in-out;
}

.comingsoon_outer .sub_banner_content .social-icon i {
  font-size: 16px;
  height: 38px;
  width: 38px;
  line-height: 36px;
  border-radius: 100px;
  text-align: center;
  color: var(--e-global-color-accent);
  background-color: transparent;
  border: 1px solid var(--e-global-color-accent);
  transition: all 0.3s ease-in-out;
}

.comingsoon_outer .sub_banner_content .social-icon a:hover i {
  color: var(--e-global-color-white);
  background: var(--e-global-color-accent);
  border: 1px solid var(--e-global-color-accent);
}

/* Back to top button */

#button {
  display: inline-block;
  background-color: var(--e-global-color-accent);
  width: 64px;
  height: 64px;
  text-align: center;
  border-radius: 8px;
  position: fixed;
  bottom: 60px;
  right: 80px;
  transition: background-color .3s, opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: spineer 2s infinite;
  animation: spineer 2s infinite;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

#button::after {
  content: "\f062";
  font-family: "Font Awesome 5 free";
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  font-weight: 600;
}

#button.show {
  opacity: 1;
  visibility: visible;
}

#button:hover {
  transform: translateY(-5px);
}

@-webkit-keyframes spineer {
  from {
    box-shadow: 0 0 0 0 rgb(42 42 42 / 40%)
  }
  to {
    box-shadow: 0 0 0 35px rgba(250, 87, 51, .01)
  }
}

@keyframes spineer {
  from {
    box-shadow: 0 0 0 0 rgb(42 42 42 / 40%)
  }
  to {
    box-shadow: 0 0 0 35px rgba(250, 87, 51, .01)
  }
}

/* Preloader -------------------------------------------------------*/

/* Loader Styles start here */
.loader-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 99999;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  font-size: 0;
  color: var(--e-global-color-accent);
  display: inline-block;
  margin: -25px 0 0 -25px;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.lead {
  font-size: 12px;
}

.loader div {
  background-color: var(--e-global-color-accent);
  display: inline-block;
  float: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  opacity: .5;
  border-radius: 50%;
  -webkit-animation: ballPulseDouble 2s ease-in-out infinite;
  animation: ballPulseDouble 2s ease-in-out infinite;
}

.loader div:last-child {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes ballPulseDouble {

  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes ballPulseDouble {

  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.grid-container-4 {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 4 items across 2 columns */
  gap: 10px;
  padding: 20px;
}

/* Grid container for 2 images */
.grid-container-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  padding: 10px;
}

/* Grid items to hold images */
.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* To ensure images don't exceed their boundaries */
}

/* For Grid 4: Set images to 300x300 */
.grid-container-4 .grid-item img {
  width: 300px;
  height: 300px;
  object-fit: cover; /* Makes sure images cover the entire box and remain proportional */
  border: 1px solid #ddd; /* Optional: Adds a border around the image */
}

/* For Grid 2: Set images to 340x340 */
.grid-container-2 .grid-item img {
  width: 340px;
  height: 340px;
  object-fit: cover; /* Ensures images fill the box, preserving aspect ratio */
  border: 1px solid #ddd;
}

/* Mobile Responsive Layout */
@media (max-width: 767px) {
  .grid-container-4,
  .grid-container-2 {
    grid-template-columns: 1fr; /* Stack items vertically on mobile */
  }

  .grid-item {
    justify-content: flex-start;
    padding: 10px 0;
  }

  /* Adjust the image sizes for mobile */
  .grid-container-4 .grid-item img,
  .grid-container-2 .grid-item img {
    width: 100%; /* Adjust image width to fill the container */
    height: auto; /* Let height adjust proportionally */
  }
}

.swiper-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.swiper {
  width: 80%;
  height: 70%;
}

.mySwiper2 .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mySwiper2 img {
  width: 100%;
  height: auto;
}

.mySwiper img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
}

.mySwiper {
  height: 100px;
  box-sizing: border-box;
  padding: 10px 0;
}

.swiper-button-next, .swiper-button-prev {
  color: white;
}

.swiper-pagination-bullet {
  background: white;
}

/* Preloader -------------------------------------------------------*/
/* Áp dụng font Inter-Medium cho toàn bộ trang */
body {
  font-family: 'Inter', sans-serif;
  font-weight: 500; /* 500 tương ứng với Inter-Medium */
}

/* Đảm bảo tất cả các thẻ đều thừa hưởng font Inter */
* {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  box-sizing: border-box; /* Đảm bảo mọi phần tử dùng box-sizing */
}

h1, h2, h3, h4, h5, h6, p, a, button, input {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

/* Định dạng cho tiêu đề */
.section-title {
  font-size: 30px;
  margin-bottom: 10px;
}

/* Định dạng cho đoạn mô tả */
.section-description {
  font-size: 17px;
  color: #666;
  margin-bottom: 35px;
}

.text-orange {
  color: #fd8916 !important
}

.pc {
  display: flex !important;
}

.mobile {
  display: none !important;
}

.inline-block {
  display: inline-block !important;
}

@media (max-width: 768px) {
  .pc {
    display: none !important;
  }

  .mobile {
    display: flex !important;
  }

  .pc .inline-block {
    display: -webkit-box !important;
  }
}

@media (max-width: 991.98px) {
  .pc {
    display: none !important;
  }

  .mobile {
    display: flex !important;
  }
}

.text-date {
  color: #00BE35 !important
}

.text-decoration-none i.fa-arrow-right {
  /* Ensure the icon transitions smoothly */
  transition: transform 0.3s ease-in-out;
}

.text-decoration-none:hover i.fa-arrow-right {
  /* Apply the beat animation when the link is hovered */
  animation: beat 0.6s ease-in-out infinite;
}

@keyframes beat {
  0%, 100% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(1.1);
  }
}

.max-1-line {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Số dòng bạn muốn hiển thị */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.max-2-line {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Số dòng bạn muốn hiển thị */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.max-3-line {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Số dòng bạn muốn hiển thị */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.owl-carousel.owl-drag .owl-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.owl-carousel.owl-drag .owl-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Ensure items are not stretched and are centered when fewer than 4 items */
.owl-carousel .owl-stage {
  display: flex;
  justify-content: center; /* Center items if fewer than 4 */
}

/* Ensure that items don't stretch to fill space */
.owl-carousel .owl-item.active {
  flex: 0 0 auto; /* Prevent items from stretching */
  width: 100%;
  max-width: 250px; /* Adjust based on the size you want */
}

/* Breadcrumb container */
.breadcrumb-nav {
  font-size: 14px;
  color: #666; /* Màu chữ mặc định */
  margin-bottom: 10px; /* Khoảng cách dưới */
  margin-top: 20px; /* Khoảng cách dưới */
  display: flex;
  align-items: center;
}

/* Liên kết trong breadcrumb */
.breadcrumb-nav a {
  color: #666; /* Màu chữ mặc định */
  text-decoration: none; /* Bỏ gạch chân */
  margin-right: 8px; /* Khoảng cách giữa các phần */
}

.breadcrumb-nav a:hover {
  color: #fd8916; /* Màu chữ khi hover */
}

/* Mũi tên phân cách giữa các phần */
.breadcrumb-nav i {
  margin: 0 8px;
  color: #999; /* Màu của mũi tên */
}

/* Màu chữ cho phần hiện tại */
.breadcrumb-nav .text-orange {
  color: #fd8916;
}

/* Category tag (Thể thao) */
/* Container holding all tags */
.category-tags {
  display: flex;
  flex-wrap: wrap; /* Cho phép các tag tự động xuống dòng nếu không đủ chỗ */
  gap: 10px; /* Khoảng cách giữa các tag */
  margin-top: 10px; /* Khoảng cách giữa tag và các phần khác */
}

/* Tag style */
.category-tag {
  background-color: #e6e6fa; /* Màu nền nhạt tương tự ảnh */
  color: #333; /* Màu chữ */
  font-size: 13px; /* Kích thước chữ */
  padding: 5px 10px; /* Khoảng cách trong tag */
  border-radius: 5px; /* Bo góc nhẹ cho tag */
  display: inline-block; /* Hiển thị dưới dạng block inline */
  text-align: center; /* Căn giữa nội dung */
  white-space: nowrap; /* Ngăn chữ xuống dòng */
}

.category-link {
  color: #007bff; /* Màu chữ cho category */
  text-decoration: none;
}

.category-link:hover {
  color: #0056b3; /* Màu khi hover */
  text-decoration: underline;
}

@media (max-width: 991.98px) {
  /* Container của breadcrumb */
  .breadcrumb-nav {
    display: flex;
    flex-wrap: nowrap; /* Ngăn breadcrumb xuống dòng */
    white-space: nowrap; /* Ngăn chữ xuống dòng */
    overflow: hidden; /* Bỏ đi phần tràn ra ngoài */
    text-overflow: ellipsis; /* Thêm dấu "..." nếu nội dung quá dài */
    font-size: 13px;
  }

  /* Phần cuối breadcrumb, có thể cắt bớt với dấu "..." */
  .breadcrumb-nav .text-orange {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100px; /* Tùy chỉnh chiều rộng tối đa mà bạn muốn cho phần tên cuối cùng */
    display: inline-block;
    vertical-align: bottom;
  }

}

.text-loadmore {
  color: #e7c582;
}

.text-loadmore:hover {
  color: var(--e-global-color-accent);
}


.text-blue {
  color: #007bff !important
}

.container {
  margin-left: 300px;
}

.supplier-content .img-fluid {
  width: 100%; /* Đảm bảo ảnh lấp đầy chiều rộng của thẻ cha */
  height: auto; /* Giữ nguyên tỷ lệ của ảnh */
  border-radius: 30px; /* Đặt border-radius theo yêu cầu */
  object-fit: cover; /* Đảm bảo ảnh bao phủ toàn bộ thẻ cha mà không bị méo */
  display: block; /* Loại bỏ khoảng trắng không mong muốn xung quanh ảnh */
}
